
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useStore } from "vuex";
import * as Yup from "yup";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import ApiService from "@/core/services/ApiService";
import { AxiosRequestConfig } from "axios";
import router from "@/router/clean";

export default defineComponent({
  name: "password-reset",
  components: {
    Field,
    Form,
    ErrorMessage,
  },

  setup() {
    const store = useStore();
    const emailMissingError = "Necessário introduzir um email";

    const submitButton = ref<HTMLButtonElement | null>(null);

    //Create form validation object
    const forgotPassword = Yup.object().shape({
      email: Yup.string().email().required().label("Email"),
    });

    //Form submit function
    const onSubmitForgotPassword = (values) => {
      // eslint-disable-next-line
      submitButton.value!.disabled = true;
      // Activate loading indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      // dummy delay
      setTimeout(() => {
        // Send login request
        ApiService.postNoAxios("/auth/redefinepassword", {
          email: values.email,
        })
          .then(() => {
            // Deactivate loading indicator
            submitButton.value?.setAttribute("data-kt-indicator", "off");
            // Show success message
            Swal.fire({
              title: "Email enviado",
              text: "Verifique o seu email para redefinir a password",
              icon: "success",
              confirmButtonText: "Ok",
            });
            router.push("/sign-in");
          })
          .catch(() => {
            // Deactivate loading indicator
            submitButton.value?.setAttribute("data-kt-indicator", "off");
            // Show error message
            Swal.fire({
              title: "Erro",
              text: "Ocorreu um erro ao enviar o email",
              icon: "error",
              confirmButtonText: "Ok",
            });
          });

        submitButton.value?.removeAttribute("data-kt-indicator");
        // eslint-disable-next-line
        submitButton.value!.disabled = false;
      }, 2000);
    };

    return {
      onSubmitForgotPassword,
      forgotPassword,
      submitButton,
      emailMissingError,
    };
  },
});
